@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap);
/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: #F7CA18;
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: #fff;
  --clr-primary-8: #fff;
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: #fff;
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #000;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: hsl(210, 36%, 96%);
  background: var(--clr-grey-10);
  color: hsl(209, 61%, 16%);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: hsl(210, 22%, 49%);
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
=============== 
Navbar
===============
*/
nav {
  background: #000;
  background: var(--clr-white);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: var(--light-shadow);
  border-bottom: solid 3px #F7CA18;
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-toggle {
  font-size: 1.5rem;
  color: #F7CA18;
  color: var(--clr-primary-5);
  background: transparent;
  border-color: transparent;
  transition: all 0.3s linear;
  transition: var(--transition);
  cursor: pointer;
}
.nav-toggle:hover {
  color: hsl(205, 86%, 17%);
  color: var(--clr-primary-1);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.navlogo{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  border-color: #F7CA18;


}
.logo {
  border-style: solid;
  border-color: #F7CA18;
  width: 140px;
  height: 120px;
  /* align-content: center; */
  overflow: hidden;
  margin-left: -20px;
  margin-top: -8px;

}
.links a {
  color: #fff;
  color: var(--clr-grey-3);
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1rem;
  transition: all 0.3s linear;
  transition: var(--transition);
}
.links a:hover {
  background: #fff;
  background: var(--clr-primary-8);
  color: #F7CA18;
  color: var(--clr-primary-5);
  padding-left: 1.5rem;
}
.social-icons {
  display: none;
}
.links-container {
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
  transition: var(--transition);
}
.show-container {
  height: 10rem;
}
@media screen and (min-width: 800px) {
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
  }
  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
  .social-icons {
    display: flex;
  }
  .social-icons a {
    margin: 0 0.5rem;
    color: #F7CA18;
    color: var(--clr-primary-5);
    transition: all 0.3s linear;
    transition: var(--transition);
  }
  .social-icons a:hover {
    color: #fff;
    color: var(--clr-primary-7);
  }
}
nav{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
html{
  scroll-behavior: smooth;
}
*, ::before,
  ::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  footer{
    position: absolute;
    /* bottom: 0; */
    /* left: 0; */
    right: 0;
    background: #000;
    height: auto;
    width: 100vw;
    padding-top: 40px;
    color: #fff;
  }
  .footerContent{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

  }
  .footerContent p{
    max-width: 500px;
    margin: 10px auto;
    line-height: 28px;
    font-size: 14px;
  }
  .socials{
    list-style: none;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    margin: 1rem 0 3rem 0;
  }
  .socials li{
    margin: 0 10px;
  }
  .footerIcons{
    font-size: 1.1rem;
  }
  .socials a{
    text-decoration: none;
    color: #fff;
  }
  .footerIcons{
    font-size: 1.5rem;
    transition: color .4s ease;
  }
.footerIcons:hover  {
  color: #F7CA18;
}
.footer-Bottom{
  background: #000;
  width: 100vw;
  padding: 20px 0;
  text-align: center;
}
.footer-Bottom p {
  font-size: 14px;
  word-spacing: 2px;
  text-transform: capitalize;
  color: #fff;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.about-section{
  display: flex;
  width: 100%;
}
.skills{
  display: flex;
  /* flex-grow: 1; */
  width: 100%;
}
.icons-shape{
  display: flex;
  align-self: center;
  border-radius: 50%;
  width: 100px; 
  height: 100px; 
  background: #F7CA18;
}
.icons-shape > header{
  align-self: center;
  /* margin-bottom: 30px; */
}
.react-icons{
  font-size: 50px; 
  color: #0c090d;
  margin-left: 25px;
}
.icons{
  display: flex;
  /* flex: 2 1; */
  flex-wrap: wrap;
  flex-direction: column;
  justify-content:  center;
  align-items: center;
  padding: 1rem;
}
.h2{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  color: #fff;

}
.paragragh{
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-top: 10px;
  text-align: center; 
  color: #fff;
  font-size: 1.1rem;

}

/***********************************************************************
                                MEDIA QURIES
  **********************************************************************/

  @media screen and (max-width: 1100px){
  .skills{
    /* flex-wrap: wrap; */
  }
}

@media screen and (max-width: 950px){
  .skills{
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }
  .paragragh{
    text-align: center;
    inline-size: 300px;
    font-size: 1.1rem;
  }
}



*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  font-family: 'IBM Plex Serif', serif;
}
.formContainer{
  /* position: relative; */
  /* bottom: 30%; */
  /* transform: translateY(-50%); */
  margin-top: 150px;
  width: 100%;
  height: 600px;
  /* Padding: 0 20px; */
  /* background: #53B3CB; */
}
.formContainer h2 {
  text-align: center;
}
.form{
  max-width: 550px;
  margin: 40px auto;;
  background: rgba(0,0,0,0.8);
  border-radius: 5px;
  display: flex;
  box-shadow: 0 0 10px rgba(0,0,0,0.13);
}
.formWord{
  display: flex;
  flex-direction: column;
  /* margin-right: 4%; */
  margin-top: -40px;
}
.formWord,msg{
  width:48;
}
.formWord, .input, .msg, textarea{
  margin: 10px 0;
  background: transparent;
  border: 0;
  /* border-bottom: 2px solid #c5ecfd; */
  padding: 10px;
  color: #fff;
  width: 100%;
  justify-content: center;
  font-size: .9rem;
  letter-spacing: .1rem;
  font-weight: 300;
}
.msg textarea{
  height: 212px;
}
::-webkit-input-placeholder{
  color: #fff;
}
::-moz-input-placeholder{
  color: #fff;
}
::-webkit-input-placeholder{
  color: #fff;
}
.btn{
  background: #F7CA18;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.2rem;
  letter-spacing: 2px;
}
@media screen and (max-width: 600px){
  .form{
    flex-direction: column;
  }
  .msg textarea{
    height: 80px;
  }
  .formWord, .msg{
    width: 100%;
  }
}

*,
::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: 'IBM Plex Serif', serif;
  background: #000;
}
ul{
  list-style-type: none;
}
a{
  text-decoration: none;
}
h1,h2,h3,h4{
  letter-spacing: 0.1rem;
  /* text-transform: capitalize; */
  line-height: 1.25;
  margin-bottom: 0.7rem;
  color: #fff;
  text-transform: uppercase; 
}
h1{
  font-size: 3rem;
}
h2{
  font-size: 2rem;
}
h3{
  font-size: 1.25rem;
}
h4{
  font-size: 0.875rem;
}
p{
  margin-bottom: 1.25rem;
  color: hsl(210, 22%, 49%);
}
@media screen and (min-width: 800px){
  h1{
  font-size: 4rem;
  }
  h2{
    font-size: 2.5rem;
  }
  h3{
    font-size: 1.75rem;
  }
  h4{
    font-size: 1rem;
  }
  h1,h2,h3,h4{
    line-height: 1;
  }
}
/* section */
.section{
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px){
  .section{
    width: 95vw;
  }
}
/* 
==========
Menu
==========
 */
.appLink{
  height: 25px;
  width: 30px;
  color: #F7CA18;
}

.menu{
  padding: 5rem 0;
}
.title{
  align-content: center;
  margin-bottom: 2rem;
}
.title h2{
  text-align: center;
  margin-top: 5rem; 
  color: #fff;
  text-transform: uppercase;
}
.underline{
  width: 5rem;
  height: 0.25rem;
  background: #F7CA18;
  margin-left: auto;
  margin-right: auto;
}
.underlineContact{
  width: 5rem;
  height: 0.25rem;
  background: #F7CA18;
  margin-left: auto;
  margin-right: auto;
}
.btn-container{
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}
.filter-btn{
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color: #c59d5f;
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 0.25rem;
}
.filter-btn:hover{
  background: #c59d5f;
  color: #fff;
}
.section-center{
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  grid-gap: 3rem 2rem;
  gap: 3rem 2rem;
  justify-items: center;
}
.project-item{
  display: grid;
  grid-gap: 1rem 2rem;
  gap: 1rem 2rem;
  max-width: 25rem;
}
.photo{
  object-fit: cover;
  height: 200px;
  width: 100%;
  border: 0.25rem solid #c59d5f;
  border-radius: 0.25rem;
  display: block;
}
.item-info {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px dotted hsl(210, 22%, 49%);
}
.item-info a{
  text-decoration: none;
  color: none;
}
.item-info h4{
  margin-bottom: 0.5rem;
}
.item-text{
  margin-bottom: 0;
  padding-top: 1rem;
}
@media screen and (min-width: 768px){
  .project-item{
    grid-template-columns: 225px 1fr;
    grid-gap: 0 1.25rem;
    gap: 0 1.25rem;
    max-width: 40rem;
  }
  .photo{
    height: 175px;
  }
}
@media screen and (min-width: 1200px){
  .section-center{
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }
  .photo{
    height: 150px;
  }
}

/****************************************************
INTRODUCTION
*****************************************************/
.header-container{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10rem;
  margin-top: 5rem;
  margin-bottom: 10rem;

}
.header-container p{
  width: 300px;
  color: #E2B13C;
  margin-top: 30px;
}
.name-header{
  font-size: 20px;
  color: #F7CA18;

}
.name-header span{
  font-size: 70px;
  color: #fff;
}
