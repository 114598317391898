@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap');

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  font-family: 'IBM Plex Serif', serif;
}
.formContainer{
  /* position: relative; */
  /* bottom: 30%; */
  /* transform: translateY(-50%); */
  margin-top: 150px;
  width: 100%;
  height: 600px;
  /* Padding: 0 20px; */
  /* background: #53B3CB; */
}
.formContainer h2 {
  text-align: center;
}
.form{
  max-width: 550px;
  margin: 40px auto;;
  background: rgba(0,0,0,0.8);
  border-radius: 5px;
  display: flex;
  box-shadow: 0 0 10px rgba(0,0,0,0.13);
}
.formWord{
  display: flex;
  flex-direction: column;
  /* margin-right: 4%; */
  margin-top: -40px;
}
.formWord,msg{
  width:48;
}
.formWord, .input, .msg, textarea{
  margin: 10px 0;
  background: transparent;
  border: 0;
  /* border-bottom: 2px solid #c5ecfd; */
  padding: 10px;
  color: #fff;
  width: 100%;
  justify-content: center;
  font-size: .9rem;
  letter-spacing: .1rem;
  font-weight: 300;
}
.msg textarea{
  height: 212px;
}
::-webkit-input-placeholder{
  color: #fff;
}
::-moz-input-placeholder{
  color: #fff;
}
::-webkit-input-placeholder{
  color: #fff;
}
.btn{
  background: #F7CA18;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.2rem;
  letter-spacing: 2px;
}
@media screen and (max-width: 600px){
  .form{
    flex-direction: column;
  }
  .msg textarea{
    height: 80px;
  }
  .formWord, .msg{
    width: 100%;
  }
}
