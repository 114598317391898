*, ::before,
  ::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  footer{
    position: absolute;
    /* bottom: 0; */
    /* left: 0; */
    right: 0;
    background: #000;
    height: auto;
    width: 100vw;
    padding-top: 40px;
    color: #fff;
  }
  .footerContent{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

  }
  .footerContent p{
    max-width: 500px;
    margin: 10px auto;
    line-height: 28px;
    font-size: 14px;
  }
  .socials{
    list-style: none;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    margin: 1rem 0 3rem 0;
  }
  .socials li{
    margin: 0 10px;
  }
  .footerIcons{
    font-size: 1.1rem;
  }
  .socials a{
    text-decoration: none;
    color: #fff;
  }
  .footerIcons{
    font-size: 1.5rem;
    transition: color .4s ease;
  }
.footerIcons:hover  {
  color: #F7CA18;
}
.footer-Bottom{
  background: #000;
  width: 100vw;
  padding: 20px 0;
  text-align: center;
}
.footer-Bottom p {
  font-size: 14px;
  word-spacing: 2px;
  text-transform: capitalize;
  color: #fff;
}