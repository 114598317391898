@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&display=swap');
*,
::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: 'IBM Plex Serif', serif;
  background: #000;
}
ul{
  list-style-type: none;
}
a{
  text-decoration: none;
}
h1,h2,h3,h4{
  letter-spacing: 0.1rem;
  /* text-transform: capitalize; */
  line-height: 1.25;
  margin-bottom: 0.7rem;
  color: #fff;
  text-transform: uppercase; 
}
h1{
  font-size: 3rem;
}
h2{
  font-size: 2rem;
}
h3{
  font-size: 1.25rem;
}
h4{
  font-size: 0.875rem;
}
p{
  margin-bottom: 1.25rem;
  color: hsl(210, 22%, 49%);
}
@media screen and (min-width: 800px){
  h1{
  font-size: 4rem;
  }
  h2{
    font-size: 2.5rem;
  }
  h3{
    font-size: 1.75rem;
  }
  h4{
    font-size: 1rem;
  }
  h1,h2,h3,h4{
    line-height: 1;
  }
}
/* section */
.section{
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px){
  .section{
    width: 95vw;
  }
}
/* 
==========
Menu
==========
 */
.appLink{
  height: 25px;
  width: 30px;
  color: #F7CA18;
}

.menu{
  padding: 5rem 0;
}
.title{
  align-content: center;
  margin-bottom: 2rem;
}
.title h2{
  text-align: center;
  margin-top: 5rem; 
  color: #fff;
  text-transform: uppercase;
}
.underline{
  width: 5rem;
  height: 0.25rem;
  background: #F7CA18;
  margin-left: auto;
  margin-right: auto;
}
.underlineContact{
  width: 5rem;
  height: 0.25rem;
  background: #F7CA18;
  margin-left: auto;
  margin-right: auto;
}
.btn-container{
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}
.filter-btn{
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color: #c59d5f;
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 0.25rem;
}
.filter-btn:hover{
  background: #c59d5f;
  color: #fff;
}
.section-center{
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  gap: 3rem 2rem;
  justify-items: center;
}
.project-item{
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
}
.photo{
  object-fit: cover;
  height: 200px;
  width: 100%;
  border: 0.25rem solid #c59d5f;
  border-radius: 0.25rem;
  display: block;
}
.item-info {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px dotted hsl(210, 22%, 49%);
}
.item-info a{
  text-decoration: none;
  color: none;
}
.item-info h4{
  margin-bottom: 0.5rem;
}
.item-text{
  margin-bottom: 0;
  padding-top: 1rem;
}
@media screen and (min-width: 768px){
  .project-item{
    grid-template-columns: 225px 1fr;
    gap: 0 1.25rem;
    max-width: 40rem;
  }
  .photo{
    height: 175px;
  }
}
@media screen and (min-width: 1200px){
  .section-center{
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }
  .photo{
    height: 150px;
  }
}

/****************************************************
INTRODUCTION
*****************************************************/
.header-container{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10rem;
  margin-top: 5rem;
  margin-bottom: 10rem;

}
.header-container p{
  width: 300px;
  color: #E2B13C;
  margin-top: 30px;
}
.name-header{
  font-size: 20px;
  color: #F7CA18;

}
.name-header span{
  font-size: 70px;
  color: #fff;
}