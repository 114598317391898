*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.about-section{
  display: flex;
  width: 100%;
}
.skills{
  display: flex;
  /* flex-grow: 1; */
  width: 100%;
}
.icons-shape{
  display: flex;
  align-self: center;
  border-radius: 50%;
  width: 100px; 
  height: 100px; 
  background: #F7CA18;
}
.icons-shape > header{
  align-self: center;
  /* margin-bottom: 30px; */
}
.react-icons{
  font-size: 50px; 
  color: #0c090d;
  margin-left: 25px;
}
.icons{
  display: flex;
  /* flex: 2 1; */
  flex-wrap: wrap;
  flex-direction: column;
  justify-content:  center;
  align-items: center;
  padding: 1rem;
}
.h2{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  color: #fff;

}
.paragragh{
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin-top: 10px;
  text-align: center; 
  color: #fff;
  font-size: 1.1rem;

}

/***********************************************************************
                                MEDIA QURIES
  **********************************************************************/

  @media screen and (max-width: 1100px){
  .skills{
    /* flex-wrap: wrap; */
  }
}

@media screen and (max-width: 950px){
  .skills{
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }
  .paragragh{
    text-align: center;
    inline-size: 300px;
    font-size: 1.1rem;
  }
}


